// _init.js
import $ from 'jquery';
import htmx from 'htmx.org';
import '../scss/styles.scss';
import '/node_modules/bootstrap-icons/font/bootstrap-icons.scss';

window.htmx = htmx;
window.jQuery = window.$ = $;
const moduleCache = new Map();
let cleanupTimeout = null;
const moduleElementMap = new Map();

// Function to dynamically import and initialize a module
async function loadModule(moduleName, element) {
  try {
    if (!moduleCache.has(moduleName)) {
      const module = await import(/* webpackChunkName: "[request]" */ `./${moduleName}.js`);
      if (module.default && typeof module.default === 'function') {
        const moduleInstance = module.default();
        moduleCache.set(moduleName, moduleInstance);
        console.debug(`Module ${moduleName} loaded and initialized`);
      }
    }

    const moduleInstance = moduleCache.get(moduleName);
    if (moduleInstance && typeof moduleInstance.attach === 'function') {
      moduleInstance.attach(element);
    }
    if (!moduleElementMap.has(moduleName)) {
      moduleElementMap.set(moduleName, new Set());
    }
    moduleElementMap.get(moduleName).add(element);

  } catch (error) {
    console.error(`Error loading module ${moduleName}:`, error);
  }
}

function cleanupModule(moduleName) {
  const moduleInstance = moduleCache.get(moduleName);
  const elements = moduleElementMap.get(moduleName);

  if (moduleInstance && typeof moduleInstance.cleanup === 'function') {
    moduleInstance.cleanup();
    console.debug(`Module ${moduleName} cleaned up`);
  }

  moduleCache.delete(moduleName);
  moduleElementMap.delete(moduleName);
}

function initializeModules(root = document) {
  console.log('Initializing modules...');
  if (root && root.querySelectorAll) {
    root.querySelectorAll('[data-module]').forEach(element => {
      const moduleNames = element.dataset.module.split(/\s+/);
      moduleNames.forEach(moduleName => {
        loadModule(moduleName, element);
      });
    });
  } else {
    console.warn('Invalid root element provided to initializeModules');
  }
}

function debouncedCleanup() {
  if (cleanupTimeout) {
    clearTimeout(cleanupTimeout);
  }
  cleanupTimeout = setTimeout(() => {
    console.log('Performing cleanup');
    moduleElementMap.forEach((elements, moduleName) => {
      elements.forEach(element => {
        if (!document.body.contains(element)) {
          elements.delete(element);
        }
      });
      if (elements.size === 0) {
        cleanupModule(moduleName);
      }
    });
    cleanupTimeout = null;
  }, 300);
}

const addOnLoad = typeof onloadAdd !== 'undefined' ? onloadAdd : (fn) => {
  window.addEventListener('load', fn);
};

addOnLoad(() => {
  initializeModules();
});

// Initialize modules after HTMX content swap
document.addEventListener('htmx:afterSwap', (event) => {
  initializeModules(event.detail.elt);
  debouncedCleanup();
});

// Trigger cleanup before HTMX removes elements
document.addEventListener('htmx:beforeCleanupElement', (event) => {
  debouncedCleanup();
});

// Safer API for manual module management
window.moduleManager = {
  load: loadModule,
  initialize: initializeModules,
  cleanup: cleanupModule
};

export { loadModule, initializeModules, cleanupModule };
